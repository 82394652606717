<template lang="pug">
v-app#app
  v-main
    v-container#app.pa-2(fill-height, align-center, justify-center)
      v-col.text-center(align-center, justify-center)
        h2 The Kenney Curriculum, teaching:
        h1 {{ selected_quote }}
        v-btn.ma-4(
          @click="current_index++",
          dark,
          color="primary",
          x-large,
          justify-center
        ) Play again
        h2 #DitchTheDraft
        v-row(justify-center)
          v-col(justify-center)
            a.mt-2.twitter-share-button(
              data-size="large",
              href="https://twitter.com/intent/tweet?text=The%20Kenney%20Curriculum%20is%20ridiculous.%20See%20for%20yourself%20%23AbEd%20%40SOSAlberta"
            )
            .fb-share-button.mt-0(
              data-href="https://ditchthedraft.studentsdeservebetter.ca",
              data-layout="button",
              data-size="large"
            )
              a.fb-xfbml-parse-ignore(
                target="_blank",
                href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fditchthedraft.studentsdeservebetter.ca%2F&amp;src=sdkpreparse"
              ) Share
        v-btn.ma-4(
          href="https://curriculum-comparison.com",
          large,
          target="_blank"
        ) See for yourself
        v-btn.ma-4(href="https://www.studentsdeservebetter.ca", large) Take Action
        v-row.mt-8.d-flex.justify-center.mb-6(max-height="40px")
          v-img.mr-2(
            justify-right,
            src="https://www.studentsdeservebetter.ca/uploads/1/2/6/8/126865987/editor/sos-wordmark.png?1617481573",
            max-width="150px",
            contain
          )
          v-img.ml-2(
            src="https://www.studentsdeservebetter.ca/uploads/1/2/6/8/126865987/published/arcd-logo-final-whitetext-1.png?1617815054",
            max-width="200px",
            contain
          )
</template>

<script>
export default {
  name: "App",

  data: () => ({
    current_index: 0,
    quotes: [
      "5 year-olds to locate gravity on a globe",
      "5 year-olds how to survive being stranded alone on an desert island",
      "8 year-olds to calculate the distance in kilometres travelled by the North West Mounted Police from Regina to Duck Lake during the 1885 Métis uprising",
      "5 year-olds their celebrity birth date",
      "7 year-olds to compare the Black Death to COVID-19",
      "6 year-olds to differentiate between the structure of an octave and a pentatonic scale",
      "9 year-olds to  make a business plan to plan for the Construction of the Canadian Pacific Railway",
      "Dinosaurs? What dinosaurs?",
      "5 year-olds distances on maps in metres and kilometres (and the relationship to yards and miles)",
      "6 year-olds to examine growth and change in the body over the course of the year",
      "7 year-olds javelin, shot put, high jump, and discus",
      "7 year-olds that legal substances can be harmful if used incorrectly",
      '7 year-olds of "time immemorial"',
      "7 year olds the history of time: Before Christ (BC), and Anno Domini (AD), decades, centuries, millenia",
      "7 year-olds Silk Road bartering",
      "7 year-olds to explain the significance of Charlemagne’s rule in the medieval era",
      "11 year-olds why the Ku Klux Klan appealed to Americans and Canadians",
      "11 year-olds the slogan of the Ku Klux Klan",
      "7 year-olds that Genghis Khan and the Mongol Empire was the largest land empire in human history",
      "11 year-olds salvation, heaven and hell",
      "11 year-olds most Albertans are Christian",
      "11 year-olds about the American “Indian wars”",
      "10 year-olds how to flirt with a widower, 1800's-style, in the back of a stagecoach",
      "7 year olds to consider: was the Magna Carta the beginning of English democracy through Parliament?",
      '6 year-olds "resource" (yes, just "resource")',
      "6 year-olds to read a pie graph showing shares of production",
      "6 year-olds the concept of money in exchange for surplus production",
      "6 year-olds to draw a diagram of rule by a chief, king, or emperor in relation to the common people",
      "6 year-olds to explain the origin of writing",
      "6 year-olds to identify remnants and ancient artifacts, such as fire pits, petroglyphs, Clovis weapons, and fossils",
      '8-year olds that "Canadian" units, like miles and feet, are still commonly in use',
      "7 year-olds to explain the scale and importance of the Mongol Empire in human history",
      "9 year-olds that	resilience is supported by developingment of perseverance over time",
      "8 year-olds about the women's horsemeat protest",
      "7 year-olds to create a timeline for the rise and fall of the Roman Empire",
      "11 year-olds	that with respect to sexual activity, self-control is a necessary virtue",
      "8 year-olds that consent is established by clearly communicating refusal"
    ],
    //
  }),
  methods: {
    shuffle: function (array) {
      var currentIndex = array.length,
        temporaryValue,
        randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    },
  },
  created: function () {
    console.log('shuffling')
    this.quotes = this.shuffle(this.quotes);
    console.log(this.quotes)
  },
  computed: {
      selected_quote: function() {
        return this.quotes[this.current_index % this.quotes.length]
    }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Oswald:wght@700&display=swap");
body {
  background: #00aad6;
}
h1 {
  font-family: "Oswald", sans-serif;
  color: #682145;
  font-size: 3.2rem;
  font-weight: 700;
  text-align: center;
}
h2 {
  font-family: "Oswald", cursive;
  color: #ffffff;
  font-size: 3em;
  text-align: center;
  font-weight: 200;
}
#app {
  /* background-color: #8f2c2c; */
  height: 100vh;
  background: #00aad6;
  position: relative;
}
#quotecard {
  background: transparent;
}
.fb_iframe_widget {
  vertical-align: top;
}
</style>